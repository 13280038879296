import React from 'react';
import Text from '../atoms/Text';
import ImageIcon from '../atoms/ImageIcon';

function ToasterError({ errors }) {
  if(typeof(errors) == 'string'){
    errors = [errors];
  }
  const err = errors.map((error, idx) => <li key={idx}>{error}</li>);
  return (
    <div className="w-full rounded-2 bg-red-200 mx-auto p-3 mb-3">
      <div className="flex flex-row items-center">
        <ImageIcon className="flex flex-row items-center" src="/images/Icon.svg" />
        <Text
          className="ml-2 text-lg font-bold text-Mude-black"
          children="Error"
        />
      </div>
      <Text
        className="text-sm ml-4 mt-2 font-medium"
        children={
          <ul>
            {err}
          </ul>
        }
      />
    </div>
  );
}

export default ToasterError;
