import React from 'react';
import InputType from '../atoms/InputType';
import InputLabel from '../atoms/InputLabel';

const InputMobileno = ({
  className='h-12 w-full border  focus:border-gray-300 border-gray-300  ring-0 focus:ring-0 rounded-lg ',
  register,
  error,
  name,
  placeholder,
  label,
  id,
  type,
  maxLength='100',
}) => {
  return (
    <div>
      <InputLabel className="text-primary-black text-sm md:text-base font-medium mb-2">
        {label}
      </InputLabel>
      <InputType
        className={className}
        name={name}
        id={id}
        type={type}
        label={label}
        placeholder={placeholder}
        register={register}
        error={error}
        maxLength={maxLength}
      />
    </div>
  );
};

export default InputMobileno;
