import { Button, Image, Text } from "atoms";
import React, {useEffect} from "react";
import { useRouter } from "next/router";
import { useState } from "react";
import EmailPage from "../Login/EmailPage";
import OTPPage from "../Login/OTPPage";
import MobilePage from "../Login/MobilePage";
import NewMobilePage from "./NewMobilePage";
import NewOTPPage from "./NewOTPPage";
import NewEmailPage from "./NewEmailPage";

function index() {
  const [loginDetails, updateLoginDetails] = useState({
    step: 1,
    mobile: null,
  });
  const [isCheckB2b, setIsCheckB2b] = useState(false);
  const [isOfflineBTB, setisOfflineBTB] = useState(false);
  const [isBTBTC, setIsBTBTC] = useState(false);
  const [showmobilepage, setshowmobilepage] = useState(false);

  const router = useRouter();

  const onClickNext = () => {
    setshowmobilepage(true);
  };
  
const handleLandingPage = (e) =>{
  if(e) e.preventDefault()
  router.push("/")
}

  useEffect(() => {
   setshowmobilepage(true)
  }, [])
  

  const renderSwitch = () => {
    switch (loginDetails.step) {
      case 1:
        return (
          <NewMobilePage
            updateLoginDetails={updateLoginDetails}
            loginDetails={loginDetails}
            setIsCheckB2b={setIsCheckB2b}
            setisOfflineBTB={setisOfflineBTB}
            setIsBTBTC={setIsBTBTC}
          />
        );
      case 2:
        return (
          <NewOTPPage
            isCheckB2b={isCheckB2b}
            isOfflineBTB={isOfflineBTB}
            isBTBTC={isBTBTC}
            updateLoginDetails={updateLoginDetails}
            loginDetails={loginDetails}
            onClickTerms={() => router.push("/terms-and-conditions")}
          />
        );
      case 3:
        return (
          <NewEmailPage
            referralCode={router.query.code ? router.query.code : ""}
          />
        );
    }
  };
  return (
    <div className="flex md:flex md:justify-center  ">
    <div className="  w-2/5 hidden lg:block h-screen bg-white  ">
      <div className=" flex justify-center items-center h-screen bg-[#029769]">
        <div className="h-62 w-64 rounded-full bg-opacity-60 flex justify-center items-center">
          <div className="space-y-8">
            <div className="flex justify-between gap-6">
              <Image src="/images/kid-1.svg" className="w-32" />
              <Image src="/images/kid-2.svg" className="w-32" />
              <Image src="/images/kid-3.svg" className="w-32" />
            </div>
            <div className="flex justify-between gap-6">
              <Image src="/images/kid-4.svg" className="w-32" />
              <Image src="/images/kid-5.svg" className="w-32" />
              <Image src="/images/kid-6.svg" className="w-32" />
            </div>
            <div className="flex justify-between gap-6">
              <Image src="/images/kid-7.svg" className="w-32" />
              <Image src="/images/kid-10.svg" className="w-32" />
              <Image src="/images/kid-9.svg" className="w-32" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="w-full lg:w-3/5 md:ml-4 bg-100% h-screen bg-bgimgmb md:bg-tablet lg:bg-Desktop bg-no-repeat  overflow-y-scroll "> */}
    <div className="w-full lg:w-3/5 md:ml-4 bg-100% h-screen bg-no-repeat  overflow-y-scroll px-5">
      <div className="flex flex-col  w-full h-screen  mx-auto items-center justify-center">
        {renderSwitch()}
      </div>
    </div>
  </div>
  );
}

export default index;
