import PropTypes from "prop-types";
const NewInputButton = ({ type, value, ...property }) => {
  return (
    <input
      className={property.className}
      disabled={property.disabled}
      className={
        property.disabled ? property.disabledClassName : property.className
      }
      value={value}
      type={type}
    />
  );
};
export default NewInputButton;

NewInputButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
