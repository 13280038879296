import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupValidator } from "../lib/yup-validator";
import InputButton from "../atoms/InputButton";
import InputType from "../atoms/InputType";
import InputLabel from "../atoms/InputLabel";
import ToasterError from "../molecules/ToasterError";
import { validateReferralCode } from "services/user.service";
import { pathOr } from "ramda";
import NewInputType from "atoms/NewInputType";
import NewInputButton from "atoms/NewInputButton";
import NewInputTypeV3 from "atoms/NewInputTypeV3";
import { Button, Title } from "atoms";
import Spinner from "atoms/Spinner";
import { preventSpecialChars } from "services/helpers.service";

function NewEmailOrganism(props) {
  const [isSubmitButtonActive, setIsSubmitButtonActive] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        fullName: yup
          .string()
          .matches(/^[^0-9]+$/, "Name should not contain numbers")
          .test("", "Name doesnot contains empty space!", function (value) {
            return value.trim().length === 0 ? false : true;
          })
          .required("Name is required"),
        referralCode: yup.string()
        .optional()  // Optional field
        .test(
          'is-valid-referral-code',
          'Referral code must be at least 10 characters',
          function (value) {
            if (value && value.length > 0) {
              return value.length >= 10;  // If referralCode is entered, ensure it's at least 10 characters
            }
            return true;  // If referralCode is empty or not entered, no validation error
          }
        )
      })
    ),
    defaultValues: {
      ...props.defaultValues,
    },
  });

  let rfrlCode = getValues("referralCode");
  useEffect(async () => {
    if (rfrlCode?.length == 10) {
      const res = await validateReferralCode(rfrlCode);
      const result = pathOr("", ["data", "entity"], res);
      if (result.isValidRefferalcode) {
        props.setIsValidReferral(true);
      } else {
        props.setIsValidReferral(false);
      }
    } else {
      props.setIsValidReferral(false);
    }
  }, [rfrlCode]);

  // const preventSpecialChars = (event) => {
  //   const value = event.target.value;
  //   event.target.value = value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
  // };

  useEffect(() => {
    const tempIsButtonActive = watch().fullName?.length > 0 &&
      watch().fullName?.trim()?.length > 0 && 
      (props.isValidReferral || 
        ((rfrlCode == "" || rfrlCode == undefined) && !props.isValidReferral))
      
    setIsSubmitButtonActive(tempIsButtonActive)
  }, [watch(), rfrlCode, props.isValidReferral])
  
  return (
    <div className=" w-full flex   ">
      <div className="flex flex-col  md:w-1/2 mx-auto justify-center">
        {props.apiError?.length > 0 && <ToasterError errors={props.apiError} />}
        <img src="/images/MB.svg"  className="w-[50px] h-[50px] mb-4"
            />
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <div className="w-full  ">
            <Title className="  mb-4">
           Almost There
            </Title>
            <InputLabel className="text-[#4E4E4E] text-base font-normal  my-5 ">
            Hey parent! create your profile.
            </InputLabel>
           
            <NewInputTypeV3
              id="fullName"
              name="fullName"
              type="text"
              placeholder="Name"
              register={register}
              onInput={preventSpecialChars}
              error={errors.fullName}
            />
            <div className="mt-4">
             
              <NewInputTypeV3
                id="email"
                name="email"
                label="Email"
                type="email"
                placeholder="Email ID (Optional)"
                register={register}
                error={errors.email}
              />
            </div>
            <div className="mt-4">
             
              <NewInputTypeV3
                id="referralCode"
                name="referralCode"
                label="Referral Code"
                type="text"
                maxLength="10"
                placeholder="Referral code (Optional)"
                register={register}
                error={errors.referralCode}
                autoComplete="off"
              />
              {!props.isValidReferral && !errors.referralCode &&
                rfrlCode != "" &&
                rfrlCode != undefined && (
                  <span className="text-red-400">Invalid Referral code</span>
                )}
              {props.isValidReferral && (
                <span className="text-green-300">Valid Referral code</span>
              )}
            </div>
          </div>

          <div className=" w-full flex flex-col justify-center items-center  mt-[5rem]">
            <Button
              onClick={props.handleLogin}
              className={
                isSubmitButtonActive
                 ? "bg-[#029769] h-[54px] rounded-[16px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold profilebgshadow hover:bg-[#F3FAF7] hover:text-[#029769] ease-linear duration-500 cursor-pointer w-full"
                      : "bg-[#fff] border border-[#2E2E2E] h-[54px] rounded-[16px]  flex justify-center items-center text-center text-[14px] text-[#000] font-semibold shadow-md hover:bg-[#F3FAF7] hover:border-[#029769] hover:text-[#029769] ease-linear duration-500 w-full cursor-not-allowed pointer-events-none"
              }
              type="submit">
              {props.userCreationLoading ? <Spinner width="6" height="6" /> : "Continue"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewEmailOrganism;
