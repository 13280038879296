import React, { useState } from "react";
import EmailOrganism from "../../organisms/EmailOrganism";
import { updateUserDetails } from "../../services/user.service";
import { getAuth } from "../../services/identity.service";
import { useRouter } from "next/router";
import { Webengage } from "constants/webengage";
import moment from "moment";
import NewEmailOrganism from "organisms/NewEmailOrganism";
import { useDispatch } from "react-redux";
import { switchKidAction } from "../store/reducers/switchKid";

const NewEmailPage = (props) => {
  const [apiError, setApiError] = useState([]);
  const [isValidReferral, setIsValidReferral] = useState(false);
  const [userCreationLoading, setUserCreationLoading] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setUserCreationLoading(true);
    const auth = getAuth();
    const userDataWithoutReferral = {
      fullName: data.fullName,
      email: data.email,
    };
    const userDataWithReferral = {
      fullName: data.fullName,
      email: data.email,
      referralcode: data.referralCode,
    };
    const res = await updateUserDetails(
      auth.id,
      isValidReferral ? userDataWithReferral : userDataWithoutReferral
    );
    if (res.data.status) {
      dispatch(switchKidAction.updateSelectedKid({
        id: null,
        fullName: "",
        photo: ""
      }))
      router.push("/children/add-child");
      window.webengage.track(Webengage.USER_LOGGED_IN, {
        TimeStamp: moment(res.data.entity[1][0].createdAt).format("lll"),
        UserId: res.data.entity[1][0].id,
        name: res.data.entity[1][0].fullName,
        mobile: res.data.entity[1][0].mobile,
        email: res.data.entity[1][0].email,
      });
      window.webengage.track(Webengage.PARENT_PROFILE_CREATION, {
        TimeStamp: moment(res.data.entity[1][0].createdAt).format("lll"),
        UserId: res.data.entity[1][0].id,
        name: res.data.entity[1][0].fullName,
        mobile: res.data.entity[1][0].mobile,
        email: res.data.entity[1][0].email,
      });
    } else {
      setApiError(res.data.message);
      setUserCreationLoading(false)
    }
  };

  return (
    <NewEmailOrganism
      userCreationLoading={userCreationLoading}
      onSubmit={onSubmit}
      setIsValidReferral={setIsValidReferral}
      isValidReferral={isValidReferral}
      apiError={apiError}
      defaultValues={{ referralCode: props.referralCode }}
    />
  );
};

export default NewEmailPage;
