import React from "react";
import Button from "../atoms/Button";
import Title from "../atoms/Title";
import InputLabel from "../atoms/InputLabel";
import Edit from "../public/images/Edit.png";
import OTPInput from "../atoms/OTPInput";
import LinkButton from "../atoms/LinkButton";
import Lebelwithicon from "../molecules/Lebelwithicon";
import ToasterError from "../molecules/ToasterError";
import Spinner from "atoms/Spinner";

function OtpVerification(props) {
  return (
    <div className=" w-full flex items-center justify-center  ">
      <div className="flex flex-col mx-4  w-full md:w-[45%]">
        {props.apiError.length > 0 && <ToasterError errors={props.apiError} />}
        <div className="mb-6">
          <Title>Login</Title>
        </div>
        <form>
          <div className="flex items-center">
            <InputLabel className="text-xl font-medium text-primary-black mb-2">
              {`Enter the 4-digit code sent to you at ${props.loginDetails.mobile}`}
            </InputLabel>
            <Lebelwithicon
              // src={Edit.src}
              className="max-w-[30px]"
              src="/images/Edit.svg"
              mobileNo={`+91 ${props.loginDetails.mobile}`}
              handleClick={props.editMobile}
            />
          </div>

          <div className="mt-8">
            <OTPInput
              length={4}
              errored={props.errored}
              handleChange={props.handleChange}
              errorMessage="Please enter valid OTP!"
              otpValue={props.otp}
            />
          </div>
          <div className=" w-full mt-6 flex flex-col justify-start items-center ">
            <LinkButton
              className=" w-full h-15 text-secondary-900 font-semibold text-sm cursor-pointer"
              type="submit"
              onClick={props.resendOtp}
            >
              Resend OTP
            </LinkButton>
          </div>
          <div className="w-full flex flex-col justify-start items-center">
            <Button
              onClick={props.handleLogin}
              className={
                props.otp.length === 4 && !props.otpLoading
                  ? "bg-primary-900 w-full h-15 rounded-25 font-medium text-xl text-white"
                  : "bg-primary-200 w-full h-15 opacity-50  rounded-25 font-medium text-xl text-primary-700 pointer-events-none"
              }
              type="submit"
            >
              {props.otpLoading ? <Spinner width="6" height="6" /> : "Continue"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OtpVerification;
