import React from "react";
import Button from "../atoms/Button";
import Title from "../atoms/Title";
import InputLabel from "../atoms/InputLabel";
import LinkButton from "../atoms/LinkButton";
import Lebelwithicon from "../molecules/Lebelwithicon";
import ToasterError from "../molecules/ToasterError";
import Spinner from "atoms/Spinner";
import NewOTPInput from "atoms/NewOTPInput";
import moment from "moment";
import { Webengage } from "constants/webengage";
import { useRouter } from "next/router";
function NewOtpVerification(props) {
  const router = useRouter()
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const viewPolicy = () => {
  
    window.webengage.track(Webengage.NEW_PRIVACY_POLICY , {
      createdAt: moment().format("LLL"),
    });
    
  window.open('https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+Privacy+Policy.pdf', "_blank");
};




const viewterms = () => {
  window.webengage.track(Webengage.NEW_TERMS_AND_CONDITION, {
    createdAt: moment().format("LLL"),
  });
  router.push("/terms-and-conditions")
 
};
  return (
    <div className=" w-full flex   ">
      <div className="flex flex-col w-full md:w-1/2 mx-auto justify-center">
        {props.apiError.length > 0 && <ToasterError errors={props.apiError} />}
        <div className="mb-3 flex gap-2 items-center ">
        <img src="/images/back.svg"  
              className="cursor-pointer "
              onClick={props.editMobile}
            />
          <Title>OTP Verifications</Title>
        </div>
        <form>
          <div className="flex items-center mb-2">
            <p className="text-[12px] font-normal text-[#4E4E4E]">
              We have sent a verification code to 
            </p>
            <div className="text-[12px] font-semibold text-NewPrimaryClor ml-1">+91 - {props.loginDetails.mobile}</div>
          </div>

          <div className="mt-14">
            {/* <InputLabel className="text-base font-medium text-gray-600 my-2">
              Secure code
            </InputLabel> */}
            <NewOTPInput
              length={4}
              errored={props.errored}
              handleChange={props.handleChange}
              errorMessage="Please enter valid OTP!"
              otpValue={props.otp}
            />
            <div className="mt-3 flex gap-1 justify-start items-center text-center w-full">
  <p>Didn’t receive the OTP? </p>
  {
              props.resendOtpTimer == 0
              ?
                <LinkButton
                  className="   text-[#029769] font-semibold text-md cursor-pointer"
                  type="submit"
                  onClick={props.resendOtp}>
                  Resend OTP
                </LinkButton>
              : <p> Resend in {formatTime(props.resendOtpTimer)}</p>
            }
   
   </div>
            {/* <InputLabel className="text-base font-medium text-gray-600 my-2">
              Do not share your secure code with anyone
            </InputLabel> */}
          </div>

          <div className="w-full flex flex-col mt-[6rem] justify-start items-center">
            <Button
              onClick={props.handleLogin}
              className={
                props.otp.length === 4 && !props.otpLoading
                 ? "bg-[#029769] h-[54px] rounded-[16px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold profilebgshadow hover:bg-[#F3FAF7] hover:text-[#029769] ease-linear duration-500 cursor-pointer w-full"
                      : "bg-[#fff] border border-[#2E2E2E] h-[54px] rounded-[16px]  flex justify-center items-center text-center text-[14px] text-[#000] font-semibold shadow-md hover:bg-[#F3FAF7] hover:border-[#029769] hover:text-[#029769] ease-linear duration-500 w-full cursor-not-allowed pointer-events-none"
              }
              type="submit">
              {props.otpLoading ? <Spinner width="6" height="6" /> : "Verify and Proceed"}
            </Button>
           
          </div>
          <div className="flex flex-col items-center gap-1 mt-4">
                <p className="text-[#6E6E6E] font-normal">By continuing, you agree to our</p>
                <div className="flex justify-center  items-center gap-1">
                <a
                className="cursor-pointer text-[#029769]"
                onClick={viewterms}
                aria-hidden="true">
                Terms and Condition
              </a>{" "}
              <p className="">And </p>
              <p
              onClick={viewPolicy}
                rel="noopener noreferrer"
                className="cursor-pointer text-[#029769]">
                Privacy Policy
              </p>
                </div>
           
            </div>
          


         
        </form>
      </div>
    </div>
  );
}

export default NewOtpVerification;
