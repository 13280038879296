import React from "react";
import { useForm } from "react-hook-form";
import { yupValidator } from "../lib/yup-validator";
import * as yup from "yup";
import InputButton from "../atoms/InputButton";
import InputMobileno from "../molecules/InputMobileno";
import Span from "../atoms/Span";
import Text from "../atoms/Text";
import ToasterError from "../molecules/ToasterError";
import Spinner from "atoms/Spinner";

function LoginOrganism(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        mobile: yup.string().required("Mobile number is required!"),
      })
    ),
    defaultValues: {
      ...props.defaultValues,
    },
  });

  return (
    <div className=" w-full flex items-center justify-center">
      <div className="flex flex-col mx-4  w-full md:w-[45%] ">
        {props.apiError.length > 0 && <ToasterError errors={props.apiError} />}
        <Text className="text-2xl font-bold  text-primary-black mb-8">
          Login
        </Text>
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <InputMobileno
            type="tel"
            label="Enter your phone number and continue "
            placeholder="Phone number"
            name="mobile"
            id="mobile"
            register={register}
            error={errors.mobile}
            maxLength="10"
          />
          <Text className="text-xs mt-6">
            By clicking to procced, I agree to the
            <div className="text-blue-400">
              <span
                className="cursor-pointer"
                onClick={props.onClickTerms}
                aria-hidden="true"
              >
                Terms and Condition
              </span>{" "}
              <Span className="text-black"> & </Span>
              <a
                href="https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+Privacy+Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                Privacy Policy
              </a>
            </div>
          </Text>
          <div className=" w-full mt-6 flex flex-col justify-center items-center ">
            {!props.loginLoading ? (
              <InputButton
                type="submit"
                value="Continue"
                className={`${
                  watch().mobile?.length === 10
                    ? "flex justify-center items-center bg-primary-900  cursor-pointer w-full h-15 py-4 px-8 rounded-25 font-medium text-xl text-white"
                    : "bg-primary-200 w-full h-15 opacity-50 rounded-25 font-medium text-xl text-primary-700 cursor-not-allowed pointer-events-none"
                }`}
              />
            ) : (
              <button
                type="submit"
                children={<Spinner width="6" height="6" />}
                className={`${"bg-primary-200 w-full h-15 opacity-50 rounded-25 font-medium text-xl text-primary-700 cursor-not-allowed pointer-events-none"}`}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginOrganism;
