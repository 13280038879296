import { useRouter } from "next/router";
import React, { useState } from "react";
import LoginOrganism from "../../organisms/LoginOrganism";
import { userLogin } from "../../services/user.service";

const MobilePage = (props) => {
  const [apiError, setApiError] = useState([]);
  const [loginLoading, setLoginLoading] = useState(false);
  const router = useRouter();

  const onSubmit = async (data) => {
    setLoginLoading(true);
    const res = await userLogin(data.mobile);
    if (res.data.status) {
      props.updateLoginDetails({ step: 2, mobile: data.mobile });
      props.setIsCheckB2b(res.data.entity.user.isBTB);
      props.setisOfflineBTB(res.data.entity.user.isOfflineBTB);
    } else {
      setApiError(res.data.message);
    }
    setLoginLoading(false);
  };

  return (
    <LoginOrganism
      onSubmit={onSubmit}
      apiError={apiError}
      loginLoading={loginLoading}
      defaultValues={{ mobile: props.loginDetails.mobile }}
      onClickTerms={() => router.push("/terms-and-conditions")}
    />
  );
};

export default MobilePage;
