import React from "react";
const NewInputType = ({
  register,
  name,
  error,
  id,
  label,
  placeholder,
  type,
  maxLength,
  className,
  disabled,
  onFocus,
  onBlur,
  max,
  autoComplete,
}) => {
  return (
    <div className="flex flex-col w-full">
      <input
        className={`${className} w-full h-12 border-[2px] focus:border-gray-300 border-gray-300 ring-0 focus:ring-0 outline-none rounded-lg `}
        {...register(id)}
        name={name}
        id={id}
        label={label}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
        autoComplete={autoComplete}
      />
      {/* <input placeholder="Type Date" type="text" onfocus="(this.type = 'date')"  id="date"></input> */}

      <div className="text-red-400">{error && error.message}</div>
    </div>
  );
};

export default NewInputType;
