import React from "react";
const NewInputTypeV3 = ({
  register,
  name,
  error,
  id,
  label,
  type,
  maxLength,
  
  disabled,
  onFocus,
  onBlur,
  max,
  autoComplete,
  className,
  placeholder,
  ...props
}) => {
  return (
    <div className="flex flex-col w-full">
      <input
        className={`${className} w-full px-4 h-12 border-[1px] focus:border-[#DDDDDD]  border-[#DDDDDD] ring-0 focus:ring-0 outline-none rounded-[12px] NewInputTpeV3Shodow `}
        {...register(id)}
        name={name}
        id={id}
        label={label}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...props}
      />
     

      <div className="text-red-400 text-xs font-semibold mt-1">{error && error.message}</div>
    </div>
  );
};

export default NewInputTypeV3;
