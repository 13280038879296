import React from "react";
import PropTypes from "prop-types";
import InputLabel from "../atoms/InputLabel";
import ImageIcon from "../atoms/ImageIcon";

function Lebelwithicon({ mobileNo, src, handleClick, className }) {
  return (
    <div className="flex flex-row items-center ">
      <InputLabel
        className="text-primary-black font-bold text-lg text-center  mr-4"
        mobileNo={mobileNo}
      />
      <ImageIcon src={src} handleClick={handleClick} className={className} />
    </div>
  );
}

export default Lebelwithicon;

Lebelwithicon.propTypes = {
  mobileNo: PropTypes.any,
  src: PropTypes.string,
};
