import React, { useEffect, useState } from "react";
import OtpVerification from "../../organisms/OtpVerification";
import { userSession, userLogin } from "../../services/user.service";
import { setAuth } from "../../services/identity.service";
import { useRouter } from "next/router";
import moment from "moment";
import { Webengage } from "constants/webengage";
import { switchKidAction } from "../store/reducers/switchKid";
import { useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "services/helpers.service";
import NewOtpVerification from "organisms/NewOtpVerification";
import { getKidWithPlansInfo } from "services/plans.service";
import * as R from "ramda";

const NewOTPPage = (props) => {
  const router = useRouter();

  const [otp, setOtp] = useState("");
  const [errored, setError] = useState(false);
  const [apiError, setApiError] = useState([]);
  const [otpLoading, setOtpLoading] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(30);
  const handleChange = (otpValue) => {
    setError(false);
    setOtp(otpValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (resendOtpTimer > 0) {
      setTimeout(() => {
        setResendOtpTimer(--resendOtpTimer)
      }, 1000);
    }
  }, [resendOtpTimer])
  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      setError(true);
    } else {
      setOtpLoading(true);
      const res = await userSession(props.loginDetails.mobile, otp);
      if (res.data.status) {
        const resKid = await getKidWithPlansInfo(res?.data?.entity?.id, res?.data?.entity?.token);

        const kidsIWithSubscription = R.pathOr(null, ["data", "entity"], resKid);
  
        setAuth(res.data.entity);
        window.webengage.user.login(res.data.entity.id);
        window.webengage.user.setAttribute("we_phone", res.data.entity.mobile);
        window.webengage.track(Webengage.USER_LOGGED_IN, {
          ParentId: res.data.entity.id,
          Mobile: res.data.entity.mobile,
          TimeStamps: moment().format(),
        });
        window.webengage.track(Webengage.NEW_USER_LOGGED_IN, {
          ParentId: res.data.entity.id,
          Mobile: res.data.entity.mobile,
          TimeStamps: moment().format(),
        });
        if (!res.data.entity.name) {
          props.updateLoginDetails((prevState) => {
            return { ...prevState, step: 3 };
          });
        } else {
          // if (res.data.entity.roles.includes("Parent Offline B2B")) {
          if (props.isOfflineBTB) {
            router.push(`/school/${res.data.entity.school?.id}/menus`);
            dispatch(
              switchKidAction.updateSelectedKid({
                id: "",
                fullName: capitalizeFirstLetter(res.data.entity?.name),
                photo: "",
              })
            );
          } else if (props.isBTBTC) {
            router.push(`/school-b2b2c/${res.data.entity.school?.id}/menus`);
          } else if (props.isCheckB2b) {
            router.push({
              pathname: "/canteen/children",
              query: { isB2B: true },
            });
          } else if(!!kidsIWithSubscription.length) {
            dispatch(switchKidAction.updateSelectedKid({
              id: kidsIWithSubscription[0].id,
              fullName: capitalizeFirstLetter(kidsIWithSubscription[0].fullName),
              photo: kidsIWithSubscription[0].photo,
            }))
            router.push(`/child/${kidsIWithSubscription[0].id}/menus`);
          }else{
            dispatch(switchKidAction.updateSelectedKid({
              id: null,
              fullName: "",
              photo: "",
            }))
            router.push("/children/add-child");
          }
        }
      } else {
        setApiError(res.data.message);
        setOtpLoading(false);
      }
    }
  };

  const resendOtp = async () => {
    await userLogin(props.loginDetails.mobile);
    setResendOtpTimer(30)
  };

  const editMobile = () => {
    props.updateLoginDetails((prevState) => {
      return { ...prevState, step: 1 };
    });
  };

  return (
   
    <NewOtpVerification
      handleLogin={handleLogin}
      loginDetails={props.loginDetails}
      otpLoading={otpLoading}
      otp={otp}
      errored={errored}
      handleChange={handleChange}
      editMobile={editMobile}
      resendOtp={resendOtp}
      apiError={apiError}
      resendOtpTimer={resendOtpTimer}
      onClickTerms={props.onClickTerms}
    />
  );
};

export default NewOTPPage;
