import React from "react";
import { useForm } from "react-hook-form";
import { yupValidator } from "../lib/yup-validator";
import * as yup from "yup";
import InputButton from "../atoms/InputButton";
import InputMobileno from "../molecules/InputMobileno";
import Span from "../atoms/Span";
import Text from "../atoms/Text";
import ToasterError from "../molecules/ToasterError";
import Spinner from "atoms/Spinner";
import NewinputMobileno from "molecules/NewinputMobileno";
import NewInputButton from "atoms/NewInputButton";
import Image from "../atoms/Image";
import NewToasterError from "molecules/NewToasterError";
import PrimaryBlackButton from "atoms/PrimaryBlackButton";
import { useRouter } from "next/router";
import { Webengage } from "constants/webengage";
import moment from "moment";
import { allowOnlyNumbers, userMobileNumberValidator } from "services/helpers.service";
function NewLoginOrganism(props) {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        mobile: yup
          .string()
          .required("Mobile number is required!")
          .matches(/^[^1-5][0-9]{9}$/, "Invalid mobile number"),
      })
    ),
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const viewPolicy = () => {
  
    window.webengage.track(Webengage.NEW_PRIVACY_POLICY , {
      createdAt: moment().format("LLL"),
    });
    
  window.open('https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+Privacy+Policy.pdf', "_blank");
};




const viewterms = () => {
  window.webengage.track(Webengage.NEW_TERMS_AND_CONDITION, {
    createdAt: moment().format("LLL"),
  });
  router.push("/terms-and-conditions")
 
};
  return (
    <div className="w-full flex flex-col ">
      <div className="flex flex-col w-full md:w-1/2 mx-auto justify-center  ">
        {props.apiError.length > 0 && (
          <div className="w-full lg:w-[75%]">
            <NewToasterError errors={props.apiError} />
          </div>
        )}
        <div className="flex flex-col justify-start relative">
          <Text className="md:text-[60px] text-[40px] font-semibold  text-NewPrimaryColor mb-2">
          Hey parent !
          </Text>
         
        </div>
        <Text className="text-[14px] font-medium  text-[#4E4E4E] ">
        Welcome to monkeybox, please enter mobile number to authenticate
        </Text>
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <div className="w-full mt-[5rem]">
            <NewinputMobileno
              type="tel"
              label="Your mobile number "
              placeholder=" Enter Mobile Number"
              name="mobile"
              id="mobile"
              register={register}
              error={errors.mobile}
              maxLength="10"
              onInput={allowOnlyNumbers}
            />
            
            <div className=" w-full mt-12 flex flex-col justify-center items-center ">
              {!props.loginLoading ? (
                <NewInputButton
                  type="submit"
                  value="Continue"
                  className={`${
                    watch().mobile?.length === 10
                      ? "bg-[#029769] h-[54px] rounded-[16px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold profilebgshadow hover:bg-[#F3FAF7] hover:text-[#029769] ease-linear duration-500 cursor-pointer w-full"
                      : "bg-[#fff] border border-[#2E2E2E] h-[54px] rounded-[16px]  flex justify-center items-center text-center text-[14px] text-[#000] font-semibold shadow-md hover:bg-[#F3FAF7] hover:border-[#029769] hover:text-[#029769] ease-linear duration-500 w-full cursor-not-allowed pointer-events-none"
                  }`}
                />
                
              ) : (
                <button
                  type="submit"
                  children={<Spinner width="6" height="6" />}
                  className={`${"bg-[#029769] h-[54px] rounded-[16px] border border-[#029769] flex justify-center items-center text-center text-[14px] text-[#fff] font-semibold profilebgshadow hover:bg-[#F3FAF7] hover:text-[#029769] ease-linear duration-500 cursor-not-allowed pointer-events-none w-full"}`}
                />
              )}
             
               <div className="flex flex-col items-center gap-1 mt-4">
                <p className="text-[#6E6E6E] font-normal">By continuing, you agree to our</p>
                <div className="flex justify-center  items-center gap-1">
                <a
                className="cursor-pointer text-[#029769]"
                onClick={viewterms}
                aria-hidden="true">
                Terms and Condition
              </a>{" "}
              <p className="">And </p>
              <p
                onClick={viewPolicy}
                className="cursor-pointer text-[#029769]">
                Privacy Policy
              </p>
                </div>
           
            </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewLoginOrganism;
