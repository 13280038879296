import Head from "next/head";
import Login from "@/components/Login/Login";
import NewLogin from "@/components/NewLogin/NewLogin";

// import React from "react";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import _ from "lodash";
import Cookies from "universal-cookie";
import { setAuth } from "services/identity.service";
import { capitalizeFirstLetter } from "services/helpers.service";
import { switchKidAction } from "@/components/store/reducers/switchKid";
import { useDispatch } from "react-redux";
import { getKidWithPlansInfo } from "services/plans.service";
const cookies = new Cookies();
import * as R from "ramda";

const Index = ({ authObject = {} }) => {
  const router = useRouter();
  const dispatch = useDispatch()

  useEffect(() => {
    parentLoginByAdmin()
  }, []);

  const parentLoginByAdmin = async () => {
    if (_.size(authObject)) {
      const authObjectData = JSON.parse(authObject)
      // document.cookie = `AUTH=${encodeURIComponent(authObject)};path=/`;
      setAuth(authObjectData)

      const resKid = await getKidWithPlansInfo(authObjectData?.id, authObjectData?.token);

      const kidsIWithSubscription = R.pathOr(null, ["data", "entity"], resKid);
  
      console.log(
        authObjectData,
        JSON.parse(authObject).id,`/child/${authObject.id}/menus`, "auth object =====");
      
      dispatch(switchKidAction.updateSelectedKid({
        id: kidsIWithSubscription[0]?.id,
        fullName: capitalizeFirstLetter(kidsIWithSubscription[0]?.fullName),
        photo: kidsIWithSubscription[0]?.photo,
      }))
      router.replace(`/child/${kidsIWithSubscription[0].id}/menus`);
    }
  }
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      {/* <Login /> */}
      <NewLogin />
    </>
  );
};

export const getServerSideProps = async ({ query }) => {
  return { props: { authObject: query?.authObject || {} } };
};

export default Index;
