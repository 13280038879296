import React from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

const NewOTPInput = ({
  otpValue,
  length,
  isNumberInput,
  separator,
  handleChange,
  errored,
  shouldAutoFocus,
  errorMessage,
}) => {
  return (
    <div className="w-full otp-input text-center items-center">
      <OtpInput
        value={otpValue}
        onChange={(value) => handleChange(value)}
        numInputs={length}
        separator={separator}
        containerStyle={{
          fontSize: "48px",
          color: "#14142B",
        }}
        placeholder="0000"
        hasErrored={errored}
        inputStyle={{
          width: "64px",
          height: "64px",
          border: "1px solid #D0D5DD",
          boxSizing: "border-box",
          marginRight: 16,
          borderRadius: "8px",
          outline: "none",
          paddingLeft: "16px", // Add padding to align text with input field
          fontSize: "25px",
          color: "#101828", // Adjust font size for placeholder text
        }}
        isInputNum={isNumberInput}
        focusStyle={{
          outline: "none",
        }}
        errorStyle={{
          border: "1px solid red",
        }}
        shouldAutoFocus={shouldAutoFocus}
      />
      {errored && (
        <div className="text-error text-xs font-semibold my-1 ml-4 md:text-center lg:text-left color-red-300">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default NewOTPInput;

NewOTPInput.propTypes = {
  otpValue: PropTypes.string,
  length: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  separator: PropTypes.elementType,
  errored: PropTypes.bool,
  isNumberInput: PropTypes.bool,
  shouldAutoFocus: PropTypes.bool,
};

NewOTPInput.defaultProps = {
  errored: false,
  isNumberInput: true,
  shouldAutoFocus: true,
};
