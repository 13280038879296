import React from "react";
import InputType from "../atoms/InputType";
import InputLabel from "../atoms/InputLabel";
import NewInputType from "atoms/NewInputType";
import NewInputTypeV3 from "atoms/NewInputTypeV3";
import NewMobileNumberInputv3 from "atoms/NewMobileNumberInputv3";

const NewinputMobileno = ({
  register,
  error,
  name,
  placeholder,
  label,
  id,
  type,
  maxLength = "100",
  ...props
}) => {
  return (
    <div>
      
      <NewMobileNumberInputv3
        name={name}
        id={id}
        type={type}
        label={label}
        placeholder={placeholder}
        register={register}
        error={error}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
};

export default NewinputMobileno;
