import * as HttpService from "./http.service";
import {
  URER_LOGIN,
  USER_SESSION,
  UPDATE_USER,
  VALIDATE_REFERRAL,
  DELETE_USER,
} from "./url.service";

export const userLogin = (mobile) => {
  return HttpService.postWithOutAuth(URER_LOGIN, { mobile });
};

export const userSession = (mobile, otp) => {
  return HttpService.postWithOutAuth(USER_SESSION, { mobile, otp });
};

export const updateUserDetails = (userId, details) => {
  return HttpService.putWithAuth(UPDATE_USER(userId), details);
};

export const validateReferralCode = (referralcode) => {
  return HttpService.postWithAuth(VALIDATE_REFERRAL(), { referralcode });
};

export const deleteUser = (parentId) => {
  return HttpService.deleteWithAuth(
    DELETE_USER(parentId)
  );
};
