import React from "react";
import ImageIcon from "./ImageIcon";
const NewMobileNumberInputv3 = ({
  register,
  name,
  error,
  id,
  label,
  type,
  maxLength,
  
  disabled,
  onFocus,
  onBlur,
  max,
  autoComplete,
  className,
  placeholder,
  ...props
}) => {
  return (
    <div className="flex flex-col w-full   h-12 border-[1px] focus:border-[#DDDDDD]  border-[#DDDDDD] ring-0 focus:ring-0 outline-none rounded-[12px] NewInputTpeV3Shodow relative">
      <ImageIcon src="/images/IFlag.svg" className='absolute top-[14px] left-2 w-[20px] h-[20px]'/>
      <input
        className={`${className} h-12 rounded-[12px] pl-10  border-none w-full focus:border-none ring-0 focus:ring-0 outline-none `}
        {...register(id)}
        name={name}
        id={id}
        label={label}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...props}
      />
     

      <div className="text-red-400">{error && error.message}</div>
    </div>
  );
};

export default NewMobileNumberInputv3;
